<template>
  <div class="app-container">
    <div class="top_select">
      <div>
        <el-button @click="handleCreate()" type="primary" size="small" plain :disabled="!$buttonStatus('bmgl_xzyjbm')">新增一级部门</el-button>
      </div>
      <div>
        <qzf-video vid="63dd60f588e65faa5d12e676c84b7d7e_6"></qzf-video>
      </div>
    </div>
    <el-table
      :data="data"
      style="width: 100%"
      border
      :height="contentStyleObj"
      v-loading="loading"
      stripe
      row-key="id"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      default-expand-all
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column prop="label" label="部门名称" min-width="250" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="leaders" label="部门主管" width="200">
      </el-table-column>
      <el-table-column prop="defaultRoleName" label="部门人员默认角色" width="125">
      </el-table-column>
      <el-table-column prop="userNum" label="部门人数">
      </el-table-column>
      <el-table-column prop="allUserNum" label="部门所有人数" min-width="96">
      </el-table-column>
      <el-table-column prop="remark" label="备注">
      </el-table-column>
      <el-table-column label="操作" align="center" width="250" class-name="small-padding fixed-width">
      <template #default="scope">
        <el-button type="primary" size="small" @click="handleCreateChildren(scope.row)" plain :disabled="!$buttonStatus('bmgl_tjzbm') || scope.row.disabled">添加子部门</el-button>
        <el-button type="primary" size="small" @click="handleUpdate(scope.row)" plain :disabled="!$buttonStatus('bmgl_bj') || scope.row.disabled">编辑</el-button>
        <el-button v-if="!scope.row.children" size="small" type="danger" @click.prevent="del(scope.row,scope.$index,data)" plain :disabled="!$buttonStatus('bmgl_sc') || scope.row.disabled">删除</el-button>
      </template>
    </el-table-column>
    </el-table>
   <!--  <tree-table :height="contentStyleObj" :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}" @getlist=init2 :data="data" :columns="columns" border ref="treeTable"/> -->
    <!-- 弹框 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close :title="textMap[dialogStatus]" v-model="dialogFormVisible" width="390px">
      <el-form ref="dataForm" :model="temp" label-position="right" label-width="120px" size="small">
          <el-form-item label="部门名称" >
            <el-input v-model="temp.name" placeholder="请输入部门名称" style="width:200px;"></el-input>
          </el-form-item>
          <el-form-item label="部门人员默认角色"  >
            <el-select v-model="temp.defaultRoleId" placeholder="选择角色" style="width:200px;margin:0">
              <el-option v-for="item in roleLlist" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备 注" class="item">
            <el-input v-model="temp.remark"  style="width:200px;"  ></el-input>
          </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取消</el-button>
        <el-button type="primary" :loading="loadingY" @click="dialogStatus==='create'?createData():updateData()" size="small">确定</el-button>
      </div>
      </template>
    </el-dialog>
    <!-- 弹框 -->
    <!-- 添加子部门 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close title="添加子部门" v-model="dialogCreateVisible" width="390px">
    <el-form :model="form" label-position="right" label-width="100px" size="small">
      <el-form-item label="添加子部门" class="item">
        <el-input v-model="form.name" placeholder="请输入部门名称" style="width:200px;"></el-input>
      </el-form-item>
      <el-form-item label="默认角色" class="item">
        <el-select v-model="form.defaultRoleId" placeholder="请选择默认角色" style="width:200px;margin: 0;">
          <el-option v-for="item in roleLlist" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogCreateVisible = false" size="small">取消</el-button>
        <el-button type="primary" :loading="loadingZ" @click="createChildren" size="small">确定</el-button>
      </div>
    </template>
  </el-dialog>
  </div>
</template>

<script>
// import treeTable from '@/components/TreeTable'
import { getBaseDepts, saveBaseDept, getSysRoles ,deleteBaseDept} from '@/api/sys.js'
export default {
  name: 'user-dep',
  components: { 
    // treeTable
   },
  data() {
    return {
      loadingZ:false,
      loadingY:false,
      id:null,
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '修改',
        create: '新增'
      },
      roleLlist: [],
      isAdmin: this.$store.getters["user/user"].isAdmin,
      temp: {},
      columns: [
        {
          text: '部门名称',
          value: 'label',
          width: 200
        },
        {
          text: '部门主管',
          value: 'leaders',
          width: 200
        },
        {
          text: '部门人员默认角色',
          value: 'defaultRoleName',
          width: 200
        },
        {
          text: '部门人数',
          value: 'userNum'
        },{
          text: '部门所有人数',
          value: 'allUserNum'
        },
        {
          text: '备注',
          value: 'remark'
        },
      ],
      data: [],
      contentStyleObj:{}, //高度变化
      form:{},
      dialogCreateVisible:false
    }
  },
  created() {
    this.contentStyleObj=this.$getHeight(190)
    this.init()
    this.initBus()
  },
  methods: {
    initBus(){
      this.$bus.off("personUpdate")
      this.$bus.on("personUpdate", (val) => {
        this.init()
      });
      this.$bus.off("addUpdate")
      this.$bus.on("addUpdate", (val) => {
        this.init()
      });
    },
    init(){
      this.getDepts()
      this.getRoles()
    },
    getRoles() {
      this.listLoading = true
      getSysRoles({}).then(response => {
        if(response.data.msg == 'success' && response.data.data.list){
          this.roleLlist = response.data.data.list
          this.roleId = response.data.data.list[0].id
        }
      })
    },
    resetTemp() {
      this.temp = {
        name: '',
        parentId: null,
      }
    },
    //新增一级
    handleCreate(){
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
    },
    createData() {
      if(!this.temp.name){
        this.$qzfMessage('请输入部门名称', 1)
        return
      }
      this.loadingY =true
      this.temp.parentId =  this.id*1
      this.temp.id = this.temp.id * 1
      saveBaseDept(this.temp).then((res) => {
        this.loadingY =false
        if(res.data.msg != "success"){
          return
        }
        this.dialogFormVisible = false
        this.$qzfMessage('创建成功')
        this.getDepts()
        this.$bus.emit("addUpdate");
      })
    },
    //编辑部门
    handleUpdate(row) {
      this.temp = Object.assign({}, row) // copy obj
      this.temp.name = this.temp.label
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
    },
    updateData() {
      if(!this.temp.name){
        this.$qzfMessage('请输入部门名称', 1)
        return
      }
      this.loadingY =true
      saveBaseDept(this.temp).then((res) => {
        this.$bus.emit("addUpdate");
        if(res.data.msg == 'success'){
          this.loadingY =false
          this.getDepts()
          this.dialogFormVisible = false
          this.$qzfMessage('修改成功')
        }else{
          this.loadingY =false
        }
      })
    },
    //添加子部门
    handleCreateChildren(row) {
      this.resetTemp1()
      this.form.parentId = row.id
      this.dialogCreateVisible = true
    },
    resetTemp1(){
      this.form = {
        name: '',
        parentId: null,
      }
    },
    createChildren(){
      this.loadingZ = true
      saveBaseDept(this.form).then((res) => {
        if(res.data.msg == 'success'){
          this.loadingZ = false
          this.$bus.emit("addUpdate");
          this.dialogCreateVisible = false
          this.$qzfMessage('创建成功')
          this.getDepts()
        }
      })
    },
    init2() {
      this.getDepts()
    },
    getDepts() {
      getBaseDepts({}).then(res => {
        if(res.data.msg == 'success' && res.data.data.list){
          let big = res.data.data.list[0]
          this.id = big.id
          this.data = big.children?big.children:[]
        }
      })
    },
    del(row,index,data){
      this.$confirm('确认删除'+row.label+'吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteBaseDept({id:row.id*1}).then(res=>{
          this.$bus.emit("addUpdate");
          if(res.data.msg != "success"){
            this.$message.error(res.data.msg);
          }else{
            this.$qzfMessage('删除成功')
            let arr = row.code.split('_')
            if(arr.length==3){  //一级删除
              let firstIndex =  data.findIndex(item => item.id === row.id);
              data.splice(firstIndex,1)
            }else{ //多级
              let parentId = arr[arr.length - 3]*1 //获取parentId
              let parentObject =  this.findNodeIndexInTree(data,row.id,parentId) //父节点对象
              if(parentObject){
                let index = parentObject.children.findIndex(item => item.id === row.id); //在父节点中获取子节点Index
                parentObject.children.splice(index,1)
                if(parentObject.children.length == 0){
                  parentObject.children = null
                }
              }
            }
            // this.getDepts()
          }
        })
      })
    },
    findNodeIndexInTree(tree, targetA, targetParentId) {
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if ( node.id == targetParentId) {
          return node;
        }
        if (Array.isArray(node.children)) {
          const childIndex = this.findNodeIndexInTree(node.children, targetA, targetParentId);
          if (childIndex !== null) {
            return childIndex;
          }
        }
      }
      return null; // Node not found
    }
  }
}
</script>

<style lang="scss" scoped>
.top_select{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.item_one {
  display: flex;
}
</style>